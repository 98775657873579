import React from "react";
import PropTypes from "prop-types";
import { Container, Typography } from "@material-ui/core";
import { graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { Header, HeaderArticle } from "components";
import { Layout, useLayoutStyles } from "containers";

const PageThanks = ({
  data: {
    config: { header, seo },
  },
  location,
}) => {
  const styles = useLayoutStyles();
  const isPaid = location.search && location.search.includes("session_id");

  const layoutProps = {
    footerProps: {
      isHidden: true,
    },
    seoProps: seo,
  };

  const headerProps = {
    buttons: [
      {
        label: "Find a job",
        path: "/",
      },
      {
        label: "Post a job",
        path: "/jobs/new/",
      },
    ],
    ...header,
  };

  return (
    <Layout {...layoutProps}>
      {isPaid ? (
        <Container>
          <HeaderArticle heading="Thank you for posting your job with us." />
          <Typography className={styles.context}>
            We've sent over a receipt to your email.
          </Typography>
          <Typography className={styles.context}>
            Your job post will be published after a quick review.
          </Typography>
          <Typography className={styles.context}>
            Reach out to{" "}
            <OutboundLink href="mailto:hey@remotecreativework.com">
              hey@remotecreativework.com
            </OutboundLink>{" "}
            with any questions.
          </Typography>
        </Container>
      ) : (
        <Header {...headerProps} />
      )}
    </Layout>
  );
};

PageThanks.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        context: PropTypes.string,
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
};

export const thanksPageQuery = graphql`
  query ThanksPageQuery {
    config: pagesYaml(id: { eq: "PageThanks" }) {
      header {
        context
        heading
      }
      id
      seo {
        title
      }
    }
  }
`;

export default PageThanks;
